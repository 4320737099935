a {
  color: #1e5e84;
  text-decoration: none; }

a:hover {
  color: #2773a4; }

/** remove this with the update of material-ui */
input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

.clear {
  clear: both; }

.tabs-container > div:first-child {
  background-color: white !important; }

.tabs-container .tab {
  height: 75px; }
  .tabs-container .tab div {
    color: #626262; }
    .tabs-container .tab div svg {
      fill: #626262 !important; }
