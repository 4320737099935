.sv_qstn .sq-root input[type='radio'],
.sv_qstn .sq-root input[type='checkbox'] {
  /* -ms-transform: scale(1.7); */
  /* IE 9 */
  /* -webkit-transform: scale(1.7); */
  /* Chrome, Safari, Opera */
  /* transform: scale(1.7);
  margin-top: 0.5em; */ }

/* .ddcontrolLabel {
  margin-left: 7px;
  line-height: 1.5em;
  font-size: 1.3em;
} */
/*
.sv-q-col-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.sv-q-col-1:nth-child(even) {
  background: lightgray;
}
*/
:root {
  --primary: #2774a3;
  --primary-light: #9ad3f0; }

.survey-rendering fieldset {
  border: 0 !important; }

.survey-rendering .sd-progress__bar {
  height: 12px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; }

.survey-rendering .sd-footer {
  justify-content: flex-end; }

.survey-rendering .sd-container-modern__title.sd-title {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px !important; }

.survey-rendering textarea {
  width: 100%; }

.survey-rendering .sd-btn {
  /* background-color: #2774a3;
  color: #ffffff; */ }

.survey-rendering .sd-progress {
  height: 12px;
  border-radius: 20px;
  margin: 12px 40px;
  background-color: var(--primary-light); }

.sd-logo img {
  height: 80px !important; }

.sd-progress__text {
  text-align: center;
  width: 100%;
  right: 0 !important; }

#sv-nav-start,
#sv-nav-start .sd-navigation__start-btn {
  width: 100%; }

.sd-row__question {
  white-space: pre-wrap !important; }

.sd-header__text h3.sd-title {
  font-size: 20px !important; }

.sv-title-actions__title {
  flex-wrap: wrap;
  width: 100% !important;
  min-width: 50% !important;
  white-space: initial; }

.sd-header__text h3 {
  text-align: center; }

.survey-rendering fieldset[role="radiogroup"] {
  display: flex;
  flex-wrap: wrap; }

.survey-rendering textarea {
  color: var(--foreground, #161616);
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.survey-rendering .btn-more-info {
  background-color: var(--primary-light) !important;
  color: var(--primary) !important;
  padding: 2px 6px;
  border-radius: 6px;
  font-size: 8px; }

.survey-rendering .btn-more-info span {
  font-size: 12px !important; }

.survey-rendering .sv-title-actions {
  flex-wrap: wrap; }

.survey-rendering .sd-action-bar {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100% !important; }

.survey-rendering .sd-action-title-bar {
  justify-content: flex-start !important; }

@media only screen and (max-width: 765px) {
  .survey-rendering .docdok-matrixdropdown > thead:first-of-type {
    display: none; } }

#more-info-modal .modal-title {
  text-align: center; }

#more-info-modal .modal-header .close {
  display: none !important; }

#more-info-modal .modal-footer, .modal-header {
  justify-content: center !important;
  border-top: 0 !important;
  border-bottom: 0 !important; }

#more-info-modal .modal-footer button {
  background-color: var(--primary-light) !important;
  color: var(--primary) !important;
  padding: 6px 18px;
  border-radius: 6px;
  border: 1px solid #9ad3f0; }

#more-info-modal .modal-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#sliderTextLeft {
  white-space: pre-line !important; }

#sliderTextRight {
  white-space: nowrap !important; }

@media (max-width: 600px) {
  #sliderTextRight {
    white-space: pre-wrap !important; } }

.more-info-body {
  word-break: break-all; }

.survey-rendering .docdok-imagepicker {
  display: flex; }

.survey-rendering .docdok-imagepicker .sd-imagepicker__image {
  height: 140px !important; }

.survey-rendering .docdok-imagepicker .sd-imagepicker__image-container {
  padding: 10px;
  background: var(--background-dim, #f3f3f3);
  border: 1px solid lightgray; }

@media (max-width: 768px) {
  .sd-imagepicker__item--checked .sd-imagepicker__image-container::before {
    height: 26px !important;
    width: 26px !important;
    background-size: 14px !important; }
  .survey-rendering .docdok-imagepicker {
    flex-direction: column; }
  .sq-root table tr td h1 {
    word-break: break-word; }
  .sd-matrix thead {
    display: none !important; } }

.docdok-image-que img {
  margin: auto;
  min-width: 40% !important;
  object-fit: contain !important; }
