.actualHealthQuestion {
  display: flex;
  font-size: 0.8em;
  justify-content: space-between;
  align-items: flex-start; }

.actualHealthSliderValue {
  font-size: 1.5em;
  flex: 1 0;
  padding: 5px;
  align-self: center; }

.actualHealthSliderItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 2 0; }

.actualHealthSliderText {
  font-size: 1.3em;
  word-break: break-word;
  text-align: center; }

.actualHealthSlider {
  height: 40vh; }

.rainbowQuestion {
  margin-top: 10vh;
  width: 100%; }

.rainbowSliderItem {
  display: grid;
  grid-template-rows: 5em 1em 2em 2em 2em; }

.rainbowSliderValue {
  display: none; }

.rainbowSliderTextLeft {
  grid-column: 1 / span 2;
  grid-row: 2 / span 2;
  word-break: break-word; }

.rainbowSliderTextRight {
  grid-column: 6 / span 1;
  grid-row: 3 / span 3;
  word-break: break-word;
  text-align: right;
  justify-self: end; }

.rainbowSlider {
  background-image: linear-gradient(to right, yellow, red) !important;
  grid-column: 1 / span 6;
  grid-row: 1;
  margin: 10px; }
