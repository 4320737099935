.action-overlay {
  visibility: block;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  position: absolute;
  right: 20px;
  height: 38px; }

.action-overlay.-mobile {
  transition: unset;
  opacity: 1; }

.action-overlay:hover {
  visibility: block;
  opacity: 1;
  transition: opacity 0.5s linear;
  background: #f2f2f2; }

.rt-tr:hover .action-overlay {
  display: block;
  opacity: 1;
  transition: opacity 0.5s linear; }
