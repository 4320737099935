.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
  color: #ff4081; }

@keyframes ellipsis {
  to {
    width: 20px; } }

@-webkit-keyframes ellipsis {
  to {
    width: 20px; } }
